import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

const baseUrl = `${environment.apiUrl}/company`;
// const baseUrl = `http://139.84.139.6:80/company`;

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private http: HttpClient) {}

  getAllCompany(status : string) {
    return this.http.get<any[]>(`${baseUrl}/getAllCompany/${status}`);
  }
  getCompany(id: string) {
    return this.http.get<any[]>(`${baseUrl}/getCompany/${id}`);
  }
  insertCompany(data: any) {
    return this.http.post<any[]>(`${baseUrl}/insertCompany`, data);
  }
  updateCompany(id: string, data: any) {
    return this.http.put<any[]>(`${baseUrl}/updateCompany/${id}`, data);
  }
  deleteCompany(id: string) {
    return this.http.put<any[]>(`${baseUrl}/deleteCompany/${id}`, null);
  }

  getAllCulture() {
    return this.http.get<any[]>(`${baseUrl}/getAllCulture`);
  }

  getAllTimeZone() {
    return this.http.get<any[]>(`${baseUrl}/getAllTimeZone`);
  }
  GetCompanyLogo() {
    return this.http.get(`${baseUrl}/GetCompanyLogo`);
  }

}
