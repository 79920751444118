<div class="d-flex align-items-center justify-content-start dropdown-group">
  <div class="dropdown-sub-group">
    <div class="text-center label">
      <label class="dropdown-sub-group-label">Storage</label>
    </div>
    <p-dropdown
      appendTo="body"
      [options]="storageList"
      [(ngModel)]="storageNewObj"
      optionLabel="storageShortCode"
      class="group-prime-dropdown"
      (onChange)="getAisle($event)"
      (onClick)="changeStatus()"
    ></p-dropdown>

  </div>
  <div style="color: gray">|</div>
  <div class="dropdown-sub-group">
    <div class="text-center label">
      <label class="dropdown-sub-group-label">Aisle</label>
    </div>
    <p-dropdown
      appendTo="body"
      [options]="aisleList"
      class="group-prime-dropdown-middle"
      optionLabel="aisleCode"
      optionValue="aisleCode"
      [(ngModel)]="aisleCode"
      (onChange)="getBay($event)"
      (onClick)="changeStatus()"
    ></p-dropdown>
  </div>

  <div style="color: gray">|</div>

  <div class="dropdown-sub-group">
    <div class="text-center label">
      <label class="dropdown-sub-group-label">Bay</label>
    </div>
    <p-dropdown
      appendTo="body"
      optionLabel="bayCode"
      [(ngModel)]="bayCode"
      [options]="bayList"
      optionValue="bayCode"
      class="group-prime-dropdown"
      (onChange)="getStorageString($event)"
      (onClick)="changeStatus()"
    ></p-dropdown>
  </div>
</div>
