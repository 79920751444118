import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PrimeModule } from 'src/app/prime-module/prime-module.module';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-dropdown-combo',
  templateUrl: './dropdown-combo.component.html',
  styleUrls: ['./dropdown-combo.component.css'],
  standalone: true,
  imports: [CommonModule, PrimeModule, FormsModule],
})
export class DropdownComboComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Output() storagestring = new EventEmitter<string>();

  storageList: any[] = [];
  aisleList: any[] = [];
  bayList: any[] = [];
  storageId?: string;
  aisleCode = '';
  bayCode = '';
  storageShortCode = '';
  storageSplitter = '';
  storageString = '';
  storageNewObj: any;
  aisleCodeFromParent: any;
  bayCodeFromParent: any;

  constructor(private storageService: StorageService) {}

  ngOnInit(): void {
    // this.getAllStorage();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && this.data) {
      this.initializeUpdateMode();
    }
    console.log(this.data);
    this.getAllStorage();
  }

  changeStatus() {
    if (this.data?.buttonText === 'Update') {
      this.data.buttonText = 'Save';
      console.log(this.data.buttonText);
    }
  }

  getAllStorage() {
    const id: any = localStorage.getItem('credentials');
    const credentials: any = JSON.parse(id);
    this.storageService
      .getAllStorage(credentials.companyId)
      .subscribe((data: any) => {
        this.storageList = data;
        if (this.data?.buttonText == 'Update') {
          this.initializeUpdateMode();
        }
      });
  }

  initializeUpdateMode(): void {
    const storageObj = this.storageList.find(
      (e: any) => e.storageShortCode === this.data.obj?.storageShortCode
    );
    if (storageObj) {
      this.storageNewObj = storageObj;
      this.aisleCodeFromParent = this.data.obj?.aisleCode;
      this.bayCodeFromParent = this.data.obj?.bayCode;
      this.getAisle(storageObj);
      console.log(storageObj);
    }
  }

  getAisle(selectedStorage: any): void {
    console.log('Selected Storage:', selectedStorage);

    const { storageId, storageSplitter, storageShortCode } =
      this.getStorageDetails(selectedStorage);

    if (!storageId) {
      console.warn(
        'Storage ID is undefined. Check the format of selected storage.'
      );
      return;
    }

    this.storageId = storageId;
    this.storageSplitter = storageSplitter || '';
    this.storageShortCode = storageShortCode;

    console.log('Storage ID:', this.storageId);

    this.storageService.aisleGetAll(this.storageId, null).subscribe((resp) => {
      this.aisleList = resp.data || [];
      if (this.data.buttonText === 'Update') {
        this.aisleCode =
          this.aisleList.find(
            (e: any) => e.aisleCode === this.aisleCodeFromParent
          )?.aisleCode || '';
        this.getBay(this.aisleCode);
      }
    });
  }

  getBay(selectedAisle: any): void {
    const aisleCode =
      this.data.buttonText === 'Update' ? selectedAisle : selectedAisle.value;
    this.aisleCode = aisleCode;

    this.storageService
      .bayGetAll(this.storageId, aisleCode)
      .subscribe((resp) => {
        this.bayList = resp || [];
        if (this.data.buttonText === 'Update') {
          this.bayCode =
            this.bayList.find((e: any) => e.bayCode === this.bayCodeFromParent)
              ?.bayCode || '';
        }
      });
  }

  getStorageString(selectedBay: any): void {
    this.bayCode = selectedBay.value;
    this.storageString =
      `${this.storageShortCode} ${this.storageSplitter} ${this.aisleCode} ${this.storageSplitter} ${this.bayCode}`.trim();
    this.emitStorageString();
  }

  emitStorageString(): void {
    this.storagestring.emit(this.storageString);
  }

  private getStorageDetails(selectedStorage: any) {
    console.log('Get Storage Details:', selectedStorage);
    if (this.data.buttonText === 'Update') {
      return selectedStorage;
    }
    if (selectedStorage && selectedStorage.value) {
      return selectedStorage.value;
    }
    console.warn('Invalid storage format in getStorageDetails.');
    return {};
  }

  clear() {
    this.storageShortCode = '';
    this.storageNewObj = {};
    this.storageSplitter = '';
    this.aisleCode = '';
    this.bayCode = '';
  }
}
