import { Injectable } from '@angular/core';
import { FileBase64ReqObj } from 'src/app/Interface/FileBase64ReqObj';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }

  async onFileChange(event: Event): Promise<FileBase64ReqObj> {
    const input = event.target as HTMLInputElement;
    const fileobj: FileBase64ReqObj = new FileBase64ReqObj(); // Ensure type safety
    if (input.files && input.files[0]) {
      const file: File = input.files[0]; // Correct type for `file`

      try {
        const base64 = await this.convertToBase64(file);
        fileobj.File = base64;
        fileobj.FileName = file.name;
      } catch (error) {
        console.error('Error converting file to Base64:', error);
      }
    }
    return fileobj;
  }

  private convertToBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }
   downloadFile(file: { contentType: string; documentFileNameShow: string; base64String: string }): void {
    // Convert base64 to Blob
    const byteCharacters = atob(file.base64String);
    const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: file.contentType });

    // Create a temporary link element
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = file.documentFileNameShow;
    // Trigger the download
    link.click();
    // Clean up the object URL
    URL.revokeObjectURL(url);
  }
  GetFile(contentType:string,base64String :string){
    return `data:${contentType};base64,${base64String}`;
  }

}
